$primary: #563852;
$hamburger-types: (collapse);
$hamburger-layer-color: $primary;
$hamburger-layer-border-radius: 0;
$hamburger-layer-width: 30px;
$hamburger-layer-height: 3px;

@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/hamburgers/_sass/hamburgers/hamburgers";

.App {
  &.solid-bg {
    &:before {
      content: "";
      background-color: $primary;
      width: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }

  > .container-fluid {
    background-color: white;
  }
}

.modal {
  &.modal-open {
    display: block;
    background-color: rgba(0, 0, 0, 0.33);
  }

  &.modal-loading {
    .modal-content {
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.remove-split {
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "-";
    font-size: 1.5rem;
    position: relative;
    top: -1px;
  }
}

.add-split {
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "+";
    font-size: 1.5rem;
    position: relative;
    top: -1px;
  }
}

span.split {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url('data:image/svg+xml,<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2" data-name="Layer 2"><g id="invisible_box" data-name="invisible box"><rect width="48" height="48" fill="none"/></g><g id="icons_Q2" data-name="icons Q2"><path d="M44,17V6a2,2,0,0,0-2-2H31a2,2,0,0,0-2,2h0a2,2,0,0,0,2,2h6.2l-14,14H6a2,2,0,0,0-2,2H4a2,2,0,0,0,2,2H23.2l14,14H31a2,2,0,0,0-2,2h0a2,2,0,0,0,2,2H42a2,2,0,0,0,2-2V31a2,2,0,0,0-2-2h0a2,2,0,0,0-2,2v6.2L26.8,24,40,10.8V17a2,2,0,0,0,2,2h0A2,2,0,0,0,44,17Z" fill="black"/></g></g></svg>');
}

table.summary-table {
  tr.main-category {
    td {
      font-weight: 700;
    }
  }
  td.sub-category {
    padding-left: 2rem;
  }

  tbody {
    td {
      vertical-align: middle;
    }
  }
}

table.transactions-table {
  tbody, tfoot {
    td {
      vertical-align: middle;
    }
  }

  thead {
    @include media-breakpoint-down(md) {
      th {
        &:nth-child(1),
        &:nth-child(5) {
          width: 25%;
        }

        &:nth-child(2) {
          width: 50%;
        }
      }
    }
  }

  tr {
    &.uncategorized {
      select.form-control {
        background-color: rgba($primary, 0.15);
        border-color: rgba($primary, 0.5);
      }
    }

    &.pending {
      td {
        color: #999;
        font-style: italic;
      }
    }
  }

  .desktop-col {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .mobile-row {
    @include media-breakpoint-up(md) {
      display: none;
    }

    @include media-breakpoint-down(md) {
      display: none;

      &.show {
        display: table-row;
      }

      td > div {
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 1fr;
      }
    }
  }

  .tag {
    background-color: $primary;
    color: white;
    display: inline-block;
    padding: 0 4px;
    border-radius: 3px;
    margin-left: 0.5rem;
    font-size: 0.875rem;
  }

  tfoot {
    font-size: 0.875rem;

    td {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .d-flex {
      > * {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }
}

.nav-col {
  position: relative;

  @include media-breakpoint-down(md) {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 90%;
    height: 100vh;
    margin-left: -90%;
    background-color: $primary;
    transition: margin-left 500ms;

    &.open {
      margin-left: 0;
    }
  }

  @include media-breakpoint-up(md) {
    &:before {
      content: "";
      background-color: $primary;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      bottom: 0;
      right: calc(var(--bs-gutter-x) * 0.5);
    }
  }
}

.hamburger {
  position: absolute;
  top: 1.5rem;
  left: 0;
  z-index: 5;

  &.is-active {
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: white;
    }
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

nav {
  position: relative;
  z-index: 2;
  padding: 2rem 0;

  h1 {
    color: white;
    font-size: 1.5rem;

    @include media-breakpoint-down(md) {
      margin-left: 3rem;
    }
  }

  ul {
    padding: 2rem 0 0;
    margin: 0;
    list-style: none;

    li {
      a {
        display: block;
        padding: 0.5rem 0;
        text-decoration: none;
        color: white;
        font-size: 0.875rem;

        &:hover,
        &.active {
          background-color: darken($primary, 10%);
          position: relative;

          &:before {
            content: "";
            position: absolute;
            right: 100%;
            width: 1000px;
            top: 0;
            bottom: 0;
            background-color: darken($primary, 10%);
          }
        }
      }
    }
  }
}

div.page-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 100vh;

  > h2 {
    font-size: 1.75rem;
    margin: 0 0 1rem;

    @include media-breakpoint-down(md) {
      margin-left: 3rem;
    }
  }
}

.ReactTags__selected {
  margin: 0 0 1rem;

  .ReactTags__tag {
    border: 1px solid $primary;
    border-radius: 3px;
    display: inline-flex;
    padding: 0 0 0 0.5rem;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    margin: 0 0.5rem 0 0;
  }

  .btn-sm {
    --bs-btn-padding-y: 0.15rem;
    --bs-btn-padding-x: 0.5rem;
  }
}

.filter-col {
  display: flex;
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  > div {
    margin: 0 0.5rem;
  }

  > .row > .col-auto {
    margin-top: 0;
  }
}

.account-row {
  margin-bottom: 1rem;
}